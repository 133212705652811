<template>
  <div id="app" class="layout v-application" data-app="true">
    <client-only>
      <aside
        v-if="getDashboardMenu.state === 'dashboard'"
        class="layout__aside"
      >
        <div class="aside__logo" @click="gotoHome()">
          <img src="~/assets/img/logo_dashboard.svg" alt="Logo" />
        </div>
        <div class="aside__selector">
          <BSelect
            v-if="organizers.length"
            class="aside__selector__margin"
            :options="organizers"
            :value="organizer.id"
            small
            rounded
            :immediate-emit="false"
            :disabled="isRuleEditPage"
            :bottom-border="false"
            data-cy="selectOrganizer"
            @input="organizerSelected"
          ></BSelect>
        </div>
        <div class="aside__content">
          <div class="aside__menu aside__menu--center">
            <div
              v-for="item in userMenuItems"
              :key="item.name"
              class="aside__item"
              :class="{
                hidden:
                  (item.type === 'admin' && !canAccessAdmin) ||
                  (item.type === 'dashboard' && !canAccessDashboard) ||
                  (item.type === 'accounting' && !canAccessAccounting)
              }"
            >
              <h3 class="aside__title">
                {{ item.label }}
              </h3>
              <ul>
                <li
                  v-for="subItem in item.subItems"
                  :key="subItem.name"
                  class="aside__main"
                  :class="{
                    active:
                      getDashboardMenuActive === subItem.name &&
                      !subItem.expanded,
                    disabled: subItem.disabled,
                    dropdown: subItem.subItems,
                    visible: subItem.subItems && subItem.expanded
                  }"
                >
                  <client-only>
                    <component :is="subItem.icon" />
                  </client-only>
                  <p
                    :class="{ dropdown__expand: subItem.subItems }"
                    :data-cy="subItem.name"
                    @click="subItem.onClick(subItem)"
                  >
                    {{ subItem.label }}
                    <IconArrowDown
                      v-if="subItem.subItems"
                      size="20"
                      light
                      :class="{ expanded: subItem.expanded }"
                    />
                  </p>
                  <div
                    v-if="subItem.subItems"
                    class="aside__subItem aside__subItem--spaced"
                    :class="{
                      visible: dropdownItems.find(
                        (dropdownItem) => dropdownItem.name === subItem.name
                      )?.expanded
                    }"
                  >
                    <ul>
                      <li
                        v-for="dropdownItem in subItem.subItems"
                        :key="dropdownItem.name"
                        :class="{
                          active: getDashboardMenuActive === dropdownItem.name
                        }"
                      >
                        <p
                          class="aside__subItem--inside"
                          @click="dropdownItem.onClick"
                        >
                          {{ dropdownItem.label }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <aside
        v-else-if="getDashboardMenu.state === 'event'"
        class="layout__aside"
      >
        <div :class="`aside__${getDashboardMenu.state}`">
          <a @click="gotoEvents()">&lt; {{ $t('sideMenu.dashboard') }}</a>
        </div>
        <div class="aside__eventInfo">
          <h3 class="aside__eventInfo__title">
            {{ getDashboardMenu.event.name }}
          </h3>
          <p>{{ $t('sideMenu.date') }}: {{ getDashboardMenu.event.date }}</p>
          <p>{{ $t('sideMenu.time') }}: {{ getDashboardMenu.event.time }}</p>
        </div>
        <div class="aside__content">
          <div class="aside__menu aside__menu--event-menu">
            <div
              v-for="item in eventMenuItems"
              :key="item.name"
              class="aside__item"
              :class="{ 'aside__item--hidden': item.hidden }"
            >
              <h3 class="aside__title aside__title--submenu">
                {{ item.label }}
              </h3>
              <ul>
                <li
                  v-for="subItem in item.subItems"
                  :key="subItem.name"
                  :class="{
                    active: getDashboardMenuActive === subItem.name,
                    dropdown: subItem.subItems,
                    visible: subItem.subItems && subItem.expanded,
                    disabled: subItem.disabled
                  }"
                >
                  <p
                    class="aside__subItem"
                    :class="{
                      'aside__subItem--hidden': subItem.hidden,
                      dropdown__expand: subItem.subItems
                    }"
                    :data-cy="subItem.name"
                    @click="subItem.onClick(subItem)"
                  >
                    {{ subItem.label }}
                    <IconArrowDown
                      v-if="subItem.subItems"
                      size="20"
                      light
                      :class="{ expanded: subItem.expanded }"
                    />
                  </p>
                  <div
                    v-if="subItem.subItems"
                    class="aside__subItem--spaced"
                    :class="{
                      visible: dropdownItems.find(
                        (dropdownItem) => dropdownItem.name === subItem.name
                      ).expanded
                    }"
                  >
                    <ul>
                      <li
                        v-for="dropdownItem in subItem.subItems"
                        :key="dropdownItem.name"
                        :class="{
                          active: getDashboardMenuActive === dropdownItem.name
                        }"
                      >
                        <p
                          class="aside__subItem--inside"
                          @click="dropdownItem.onClick"
                        >
                          {{ dropdownItem.label }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <aside
        v-else-if="getDashboardMenu.state === 'profile'"
        class="layout__aside"
      >
        <div class="aside__logo" @click="gotoHome()">
          <img src="~/assets/img/logo_dashboard.svg" alt="Logo" />
        </div>
        <div class="aside__content">
          <div class="aside__menu aside__menu--profile">
            <div
              v-for="item in profileMenuItems"
              :key="item.name"
              class="aside__item"
            >
              <h3 class="aside__title aside__title--profile-submenu">
                {{ item.label }}
              </h3>
              <ul>
                <li
                  v-for="subItem in item.subItems"
                  :key="subItem.name"
                  :class="{
                    active: getDashboardMenuActive === subItem.name
                  }"
                >
                  <p
                    class="aside__subItem"
                    :class="{ 'aside__subItem--hidden': subItem.hidden }"
                    @click="subItem.onClick(subItem)"
                  >
                    {{ subItem.label }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <div class="layout__content">
        <client-only>
          <BHeader :color="headerColor" :is-dashboard="true"></BHeader>
        </client-only>
        <div class="content">
          <nuxt />
        </div>
      </div>
    </client-only>
    <BAlert></BAlert>
    <BLoader v-show="getLoading"></BLoader>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BHeader from '~/components/general/BHeader';
import BSelect from '~/components/general/BSelect.vue';
import BLoader from '~/components/general/BLoader';
import { EVENT_STATES, EVENT_TYPES } from '~/utils/utils';
import IconCalendar from '~/components/general/icons/IconCalendar.vue';
import IconLocation from '~/components/general/icons/IconLocation.vue';
import IconPeople from '~/components/general/icons/IconPeople.vue';
import IconOrganizer from '~/components/general/icons/IconOrganizer.vue';
import IconImpersonate from '~/components/general/icons/IconImpersonate.vue';
import IconReporting from '~/components/general/icons/IconReporting.vue';
import IconOrders from '~/components/general/icons/IconOrders.vue';
import IconArrowSwapHorizontal from '~/components/general/icons/IconArrowSwapHorizontal.vue';
import IconUserTag from '~/components/general/icons/IconUserTag.vue';
import IconUserSquare from '~/components/general/icons/IconUserSquare.vue';
import IconGeneral from '~/components/general/icons/IconGeneral.vue';
import IconPassword from '~/components/general/icons/IconPassword.vue';
import IconPrivacy from '~/components/general/icons/IconPrivacy.vue';
import IconClipboard from '~/components/general/icons/IconClipboard.vue';
import IconArrowDown from '~/components/general/icons/IconArrowDown.vue';
import NotificationIcon from '~/components/common/icons/Notification.vue';
import IconMail from '~/components/common/icons/Mail.vue';
import BAlert from '~/components/general/BAlert.vue';
import IconDocument from '~/components/general/icons/IconDocument.vue';

export default {
  name: 'OrganizerLayout',
  components: {
    BHeader,
    BSelect,
    IconCalendar,
    IconLocation,
    IconPeople,
    IconOrganizer,
    IconImpersonate,
    IconReporting,
    IconGeneral,
    IconPassword,
    IconPrivacy,
    IconArrowSwapHorizontal,
    IconUserTag,
    IconUserSquare,
    IconClipboard,
    IconArrowDown,
    IconMail,
    BLoader,
    IconOrders,
    BAlert,
    IconDocument,
    NotificationIcon
  },
  data() {
    return {
      themePath: this.$vuetify.theme.themes.light,
      event: {},
      eventStates: EVENT_STATES,
      eventTypes: EVENT_TYPES,
      dropdownItems: [
        {
          name: 'kyc',
          expanded: false
        },
        {
          name: 'seatingPlan',
          expanded: false
        },
        {
          name: 'nftCollectibles',
          expanded: false
        }
      ],
      organizers: [],
      organizer: null
    };
  },
  computed: {
    ...mapGetters('user', [
      'canInviteUser',
      'hasRoleForOrg',
      'canAccessMyEvents',
      'canAccessLocation',
      'canAccessTransactionRules',
      'canAccessOrganizer',
      'canAccessUsers',
      'canAccessEventInfo',
      'canAccessReporting',
      'canAccessSendTickets',
      'canAccessViewOrders',
      'canAccessSendEmails',
      'canAccessManageAccounts',
      'canAccessNFTCollectibles',
      'canSendEventNotifications',
      'canSendGlobalNotifications',
      'getUser'
    ]),
    ...mapGetters('app', [
      'getDashboardAsideMenu',
      'getDashboardMenu',
      'getDashboardMenuActive',
      'getEventSendTickets',
      'getOrganizer',
      'getOrganizers',
      'getOrganizerInToken',
      'getLoading'
    ]),
    ...mapGetters('events', ['getCurrentEvent']),
    canAccessDashboard() {
      return (
        this.canAccessMyEvents(this.organizer?.name) ||
        this.canAccessNFTCollectibles(this.organizer?.name) ||
        this.canAccessLocation(this.organizer?.name) ||
        this.canAccessTransactionRules(this.organizer?.name)
      );
    },
    canAccessAdmin() {
      return (
        this.canAccessOrganizer(this.organizer?.name) ||
        this.canAccessUsers(this.organizer?.name) ||
        this.canAccessManageAccounts(this.$mainOrganizer)
      );
    },
    canAccessAccounting() {
      return (
        this.canAccessReporting(this.organizer?.name) ||
        this.canAccessViewOrders(this.organizer?.name)
      );
    },
    isRecurring() {
      return this.event.type === this.eventTypes.RECURRING;
    },
    isOccurrence() {
      return this.event.type === this.eventTypes.OCCURRENCE;
    },
    isDraft() {
      return this.event.status === this.eventStates.DRAFT;
    },
    isEdit() {
      return this.$route.params.id !== undefined;
    },
    headerColor() {
      return this.themePath.backgroundPrimary.base;
    },
    isRuleEditPage() {
      return this.$route.params.ruleId !== undefined;
    },
    eventHasTicketConfigs() {
      return this.getCurrentEvent.ticketConfig?.length > 0;
    },
    userMenuItems() {
      return this.menuItems.filter((item) => {
        item.subItems = item.subItems.filter((subItem) => {
          return subItem.canAccess;
        });
        return item.subItems;
      });
    },
    menuItems() {
      const vm = this;
      return [
        {
          label: this.$t('sideMenu.dashboard'),
          name: 'dashboard',
          type: 'dashboard',
          subItems: [
            {
              label: this.$t('sideMenu.myEvents'),
              name: 'my-events',
              icon: 'IconCalendar',
              // determines whether the element will be shown as an ordinary list item or if it can be expanded to show submenu
              isExpandable: () => {
                return this.isEdit;
              },
              onClick: () => {
                this.$router.push(this.localePath('/my-events'));
              },
              canAccess: this.canAccessMyEvents(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.nftCollectibles'),
              name: 'nftCollectibles',
              expanded: vm.getMenuState('nftCollectibles'),
              icon: 'IconClipboard',
              subItems: [
                {
                  label: this.$t('sideMenu.collections'),
                  name: 'collections',
                  onClick: () => {
                    this.$router.push(
                      this.localePath('/nft-collectibles/collections')
                    );
                  },
                  canAccess: this.canAccessNFTCollectibles(this.organizer?.name)
                },
                {
                  label: this.$t('sideMenu.airDrops'),
                  name: 'airDrops',
                  onClick: () => {
                    this.$router.push(
                      this.localePath('/nft-collectibles/air-drops')
                    );
                  },
                  canAccess: this.canAccessNFTCollectibles(this.organizer?.name)
                }
              ],
              onClick(item) {
                vm.updateMenu(item);
              },
              canAccess:
                this.canAccessNFTCollectibles(this.organizer?.name) &&
                this.$showNftCollectibles
            },
            {
              label: this.$t('sideMenu.location'),
              name: 'location',
              icon: 'IconLocation',
              onClick: () => {
                this.$router.push(this.localePath('/location'));
              },
              canAccess: this.canAccessLocation(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.transactionRules'),
              name: 'transactionRules',
              icon: 'IconArrowSwapHorizontal',
              onClick: () => {
                this.$router.push(this.localePath('/transaction-rules'));
              },
              canAccess: this.canAccessTransactionRules(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.pdfTemplates'),
              name: 'pdfTemplates',
              icon: 'IconDocument',
              onClick: () => {
                this.$router.push(this.localePath('/pdf-templates'));
              },
              canAccess:
                this.canAccessMyEvents(this.organizer?.name) &&
                this.$pdfTemplates
            }
          ]
        },
        {
          label: this.$t('sideMenu.accounting'),
          name: 'accounting',
          type: 'accounting',
          subItems: [
            {
              label: this.$t('sideMenu.reporting'),
              name: 'reporting',
              icon: 'IconReporting',
              onClick: () => {
                this.$router.push(this.localePath('/reporting'));
              },
              canAccess:
                this.canAccessReporting(this.organizer?.name) &&
                this.$showOrganizerReportsDashboard
            },
            {
              label: this.$t('sideMenu.orders'),
              name: 'orders',
              icon: 'IconOrders',
              onClick: () => {
                this.$router.push(this.localePath('/orders'));
              },
              canAccess: this.canAccessViewOrders(this.organizer?.name)
            }
          ]
        },
        {
          label: this.$t('sideMenu.admin'),
          name: 'admin',
          type: 'admin',
          subItems: [
            {
              label: this.$t('sideMenu.organizers'),
              name: 'organizers',
              icon: 'IconUserTag',
              onClick: () => {
                this.$router.push(this.localePath('/organizer/list'));
              },
              canAccess: this.canAccessOrganizer(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.users'),
              name: 'users',
              icon: 'IconUserSquare',
              onClick: () => {
                this.$router.push(this.localePath('/users'));
              },
              canAccess: this.canAccessUsers(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.manageAccounts'),
              name: 'manage-accounts',
              icon: 'IconPeople',
              onClick: () => {
                this.$router.push(this.localePath('/manage-accounts'));
              },
              canAccess: this.canAccessManageAccounts(this.$mainOrganizer)
            },
            {
              label: this.$t('sideMenu.admin.sendNotifications'),
              name: 'send-notifications',
              icon: 'NotificationIcon',
              onClick: () => {
                this.$router.push(this.localePath('/send-notifications'));
              },
              canAccess: this.canSendGlobalNotifications(this.$mainOrganizer)
            },
            {
              label: this.$t('sideMenu.admin.sendNewsletter'),
              name: 'send-newsletter',
              icon: 'IconMail',
              onClick: () => {
                this.$router.push(this.localePath('/send-newsletter'));
              },
              canAccess: this.canSendGlobalNotifications(this.$mainOrganizer)
            }
          ]
        }
      ];
    },
    eventUrl() {
      return this.localePath(
        `/${this.$route.params.tenant}/organizer/${this.organizer.name}/event/${this.$route.params.id}`
      );
    },
    eventMenuItems() {
      const vm = this;
      return [
        {
          label: this.$t('sideMenu.details'),
          name: 'eventDetails',
          hidden: false,
          subItems: [
            {
              label: this.$t('sideMenu.eventInfo'),
              name: 'eventInfo',
              hidden: false,
              onClick: () => {
                if (this.isEdit) {
                  this.$router.push(this.localePath(`${this.eventUrl}/edit`));
                }
              },
              canAccess: this.canAccessEventInfo(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.occurrences'),
              name: 'occurrences',
              hidden: false,
              disabled: !this.isRecurring,
              onClick: () => {
                this.$router.push(
                  this.localePath(`${this.eventUrl}/occurrences`)
                );
              },
              canAccess: this.canAccessEventInfo(this.organizer?.name)
            }
          ]
        },
        {
          label: this.$t('sideMenu.tickets'),
          name: 'eventTickets',
          hidden: false,
          subItems: [
            {
              label: this.$t('sideMenu.tickets'),
              name: 'tickets',
              hidden: false,
              disabled: !this.isEdit,
              onClick: () => {
                this.$router.push(this.localePath(`${this.eventUrl}/tickets`));
              },
              canAccess: this.canAccessEventInfo(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.promotions'),
              name: 'promotions',
              hidden: false,
              disabled: !this.isEdit,
              onClick: () => {
                this.$router.push(
                  this.localePath(`${this.eventUrl}/tickets/promotions`)
                );
              },
              canAccess: this.canAccessEventInfo(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.ticketsNftMedia'),
              name: 'nftMedia',
              disabled: !this.isEdit || !this.eventHasTicketConfigs,
              onClick: () => {
                this.$router.push(
                  this.localePath(`${this.eventUrl}/tickets-media`)
                );
              },
              canAccess: this.canAccessEventInfo(this.organizer?.name)
            }
          ]
        },
        {
          label: this.$t('sideMenu.settings'),
          name: 'eventSettings',
          hidden: this.isRecurring,
          subItems: [
            {
              label: this.$t('sideMenu.sectors'),
              name: 'sectors',
              disabled:
                this.isRecurring || !this.isEdit || !this.eventHasTicketConfigs,
              onClick: () => {
                this.$router.push(this.localePath(`${this.eventUrl}/sectors`));
              },
              canAccess: this.canAccessEventInfo(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.discounts'),
              name: 'discounts',
              disabled:
                this.isRecurring || !this.isEdit || !this.eventHasTicketConfigs,
              onClick: () => {
                this.$router.push(
                  this.localePath(`${this.eventUrl}/discounts`)
                );
              },
              canAccess: this.canAccessEventInfo(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.timeslots'),
              name: 'timeslots',
              hidden: this.isRecurring,
              disabled:
                this.isPublishedEvent(this.event) ||
                !this.eventHasTicketConfigs ||
                this.isRecurring ||
                !this.isEdit,
              onClick: () => {
                this.$router.push(
                  this.localePath(`${this.eventUrl}/timeslots`)
                );
              },
              canAccess: this.canAccessEventInfo(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.seatingPlan'),
              name: 'seatingPlan',
              disabled: this.isRecurring || !this.isEdit,
              expanded: vm.getMenuState('seatingPlan'),
              subItems: [
                {
                  label: this.$t('sideMenu.floorChart'),
                  name: 'seatingPlanChart',
                  onClick: () => {
                    this.$router.push(
                      this.localePath(`${this.eventUrl}/seatmap/chart`)
                    );
                  },
                  canAccess: this.canAccessEventInfo(this.organizer?.name)
                },
                {
                  label: this.$t('sideMenu.manageSeats'),
                  name: 'seatingPlanManageSeats',
                  onClick: () => {
                    this.$router.push(
                      this.localePath(`${this.eventUrl}/seatmap/manage-seats`)
                    );
                  },
                  canAccess: this.canAccessEventInfo(this.organizer?.name)
                }
              ],
              onClick(item) {
                vm.updateMenu(item);
              },
              canAccess: this.canAccessEventInfo
            },
            {
              label: this.$t('sideMenu.kyc'),
              name: 'kyc',
              disabled:
                this.isRecurring ||
                !this.isEdit ||
                this.isOccurrence ||
                !this.eventHasTicketConfigs,
              expanded: vm.getMenuState('kyc'),
              subItems: [
                {
                  label: this.$t('sideMenu.addQuestion'),
                  name: 'addQuestion',
                  onClick: () => {
                    this.$router.push(
                      this.localePath(
                        `/${this.$route.params.tenant}/organizer/${this.$route.params.organizerName}/event/${this.$route.params.id}/questionnaire/add`
                      )
                    );
                  },
                  canAccess: this.canAccessEventInfo
                },
                {
                  label: this.$t('sideMenu.manageQuestions'),
                  name: 'manageQuestions',
                  onClick: () => {
                    this.$router.push(
                      this.localePath(
                        `/${this.$route.params.tenant}/organizer/${this.$route.params.organizerName}/event/${this.$route.params.id}/questionnaire/manage`
                      )
                    );
                  },
                  canAccess: this.canAccessEventInfo
                }
              ],
              onClick(item) {
                vm.updateMenu(item);
              },
              canAccess: this.canAccessEventInfo(this.organizer?.name)
            }
          ]
        },
        {
          label: this.$t('sideMenu.accounting'),
          name: 'eventAccounting',
          hidden: this.isRecurring && !this.$showReportsDashboard,
          subItems: [
            {
              label: this.$t('sideMenu.reportingDashboard'),
              name: 'reportingDashboard',
              disabled: !this.isEdit,
              hidden: !this.$showReportsDashboard,
              onClick: () => {
                this.$router.push(
                  this.localePath(`${this.eventUrl}/reporting`)
                );
              },
              canAccess: this.canAccessReporting(this.organizer?.name)
            },
            {
              label: this.$t('myEvents.viewOrders'),
              name: 'viewOrders',
              disabled: !this.isEdit,
              hidden: this.isRecurring,
              onClick: () => {
                this.$router.push(this.localePath(`${this.eventUrl}/orders`));
              },
              canAccess: this.canAccessViewOrders(this.organizer?.name)
            }
          ]
        },
        {
          label: this.$t('sideMenu.actions'),
          name: 'eventActions',
          hidden: this.isRecurring,
          subItems: [
            {
              label: this.$t('sideMenu.sendEmails'),
              name: 'sendEmails',
              disabled: !this.isEdit || this.isDraft,
              hidden: this.isRecurring,
              onClick: () => {
                this.$router.push(
                  this.localePath(`${this.eventUrl}/send-emails`)
                );
              },
              canAccess: this.canAccessSendEmails(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.sendTickets'),
              name: 'sendTickets',
              disabled: !this.getEventSendTickets || !this.isEdit,
              hidden: this.isRecurring,
              onClick: () => {
                this.$router.push(
                  this.localePath(`${this.eventUrl}/send-tickets`)
                );
              },
              canAccess: this.canAccessSendTickets(this.organizer?.name)
            },
            {
              label: this.$t('sideMenu.sendNotifications'),
              name: 'sendNotifications',
              disabled:
                !this.isEdit || this.isDraft || !this.eventHasTicketConfigs,
              hidden: this.isRecurring,
              onClick: () => {
                this.$router.push(
                  this.localePath(`${this.eventUrl}/send-notifications`)
                );
              },
              canAccess: this.canSendEventNotifications(this.organizer?.name)
            }
          ]
        }
      ];
    },
    profileMenuItems() {
      return [
        {
          label: this.$t('sideMenu.profile'),
          name: 'profile',
          type: 'profile',
          hidden: false,
          subItems: [
            {
              label: this.$t('sideMenu.profile.general'),
              name: 'general',
              icon: 'IconGeneral',
              hidden: false,
              onClick: () => {
                this.$router.push(this.localePath('/profile'));
              },
              canAccess: true
            },
            {
              label: this.$t('sideMenu.profile.password'),
              name: 'password',
              icon: 'IconPassword',
              hidden: false,
              onClick: () => {
                this.$router.push(this.localePath('/profile/password'));
              },
              canAccess: true
            },
            {
              label: this.$t('sideMenu.profile.privacy'),
              name: 'privacy',
              icon: 'IconPrivacy',
              hidden: false,
              onClick: () => {
                this.$router.push(this.localePath('/profile/privacy'));
              },
              canAccess: true
            }
          ]
        }
      ];
    },
    urlHasEventId() {
      return this.$route.params.id;
    }
  },
  watch: {
    urlHasEventId(newValue) {
      if (newValue) {
        this.getEvent();
      }
    }
  },
  async mounted() {
    if (!this.$auth.loggedIn) {
      this.$router.replace('/');
    }
    this.$nuxt.$on('getEventData', this.getEvent);
    if (this.$route.params?.id) {
      this.getEvent();
    }
    this.organizer = this.getOrganizer;
    this.organizers = this.getOrganizers;

    // Handles the issue with the profile page of regular users failing since they have no organizers
    if (this.organizers?.length > 0) {
      await this.changeToOrg(this.organizer.name);
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('getEventData');
  },
  methods: {
    async impersonateUser(userId, organizerId, token) {
      const newToken = await this.$services.account.impersonate({
        user_id: userId,
        organizer_id: organizerId,
        token
      });
      const user = await this.$services.account.updateTokenImpersonate(
        this.$auth,
        newToken.data.token
      );
      this.$store.dispatch('user/setUser', user);
      this.$store.dispatch('app/setOrganizerInToken', organizerId);
    },
    async getEvent() {
      try {
        const event = await this.$bamApi.event.getOrganizerEvent(
          {
            organizerId: this.$route.params.organizerName,
            id: parseInt(this.$route.params.id)
          },
          {
            with: {
              ticket_config: {
                ticket_discount: true,
                depends_on: true
              },
              subcategory: true,
              ticket_format: true,
              ticket_discount: {
                ticket_config: true
              }
            }
          }
        );

        this.$store.dispatch('events/setCurrentEvent', event);
        this.event = event;

        this.$nuxt.$emit('currentEventReady');
      } catch (e) {
        if (e?.response?.status === 401) {
          this.$notifier.showMessage({
            content: this.$t('errors.editEventPermissions'),
            type: 'error'
          });
          this.$router.replace(this.localePath('/'));
        } else {
          this.$notifier.showMessage({
            content: this.$t('errors.fetchEvent'),
            type: 'error'
          });
        }
      }
    },
    updateMenu(item) {
      this.dropdownItems.forEach((dropdownItem) => {
        if (dropdownItem.name === item.name) {
          dropdownItem.expanded = !dropdownItem.expanded;
        } else {
          dropdownItem.expanded = false;
        }
      });
    },
    getMenuState(item) {
      return this.dropdownItems.find(
        (dropdownItem) => dropdownItem.name === item
      )?.expanded;
    },
    async organizerSelected(selectedOrgId) {
      if (!this.$auth.loggedIn) {
        return;
      }
      const oldOrgName = this.getOrganizer.name;
      this.organizer = this.organizers.find((org) => org.id === selectedOrgId);
      const orgChangeHappened = oldOrgName !== this.organizer.name;

      this.$store.commit('app/setOrganizer', this.organizer);

      // Avoids the infinite loop when doing an impersonate
      if (orgChangeHappened) {
        await this.changeToOrg(this.getOrganizer.name);
      }
      this.$nuxt.$emit('orgSelected', this.organizer);
    },
    async changeToOrg(organizerName) {
      const impersonatedToken = localStorage.getItem('impersonated_by_token');
      if (impersonatedToken) {
        await this.impersonateUser(
          this.getUser.id,
          organizerName,
          impersonatedToken
        );
      } else {
        try {
          // when switching organizer we make sure the bam SDK is set to use the correct one
          this.$bamApi.useOrganizer(organizerName);
          await this.$services.account.refreshToken(this.$auth, organizerName);
          this.$store.commit('app/setOrganizerInToken', organizerName);
        } catch (e) {
          this.$auth.logout();
          this.$api.setHeader('Authorization', null);
          this.$store.commit('user/resetUser');
          this.$store.commit('app/clearAuthData');
          this.gotoHome();
        }
      }
    },
    gotoHome() {
      this.$router.push(this.localePath('/'));
    },
    onItemClick(item) {
      this.$store.commit('app/setDashboardAsideMenu', false);
      this.selectedItem = item.name;
      item.onClick();
    },
    isPublishedEvent(event) {
      return event.status === this.eventStates.PUBLISHED;
    },
    gotoEvents() {
      this.$router.push(this.localePath('/my-events'));
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/_scss/_utils/variables.scss';

.layout {
  min-height: 100vh;
  background-color: var(--v-backgroundGray-lighten2);

  &__aside {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: var(--v-backgroundPrimary-base);
    padding: 24px 24px;
    width: $sidebar-width;
  }

  &__content {
    margin-left: $sidebar-width;
    width: calc(
      100% - #{$sidebar-width}
    ); // Subtract the width of the side menu from 100%
    .content {
      padding: 32px 64px 0 64px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
  }
}

.aside {
  &__selector {
    padding-top: 20px;
    padding-bottom: 10px;

    &__margin {
      width: auto;
    }
  }

  &__content {
    height: calc(100vh - 152px);
    overflow: auto;
    margin-right: -24px;
    /* For Webkit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--v-backgroundPrimary-lighten4);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* For Firefox */
    scrollbar-width: auto;
    scrollbar-color: var(--v-backgroundPrimary-lighten4);
  }

  &__event {
    a {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: var(--v-textPrimary-lighten5);
      cursor: pointer;
    }

    padding-left: 28px;
    margin: 0 -28px;
  }

  &__eventInfo {
    color: var(--v-textPrimary-lighten5);
    padding: 24px;
    padding-bottom: 36px;
    margin: 0 -24px;
    border-bottom: 1px solid var(--v-borderPrimary-lighten2);

    &__title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h3 {
      font-size: 18px;
      font-variant: 600;
      margin-bottom: 16px;
    }

    p {
      font-size: 13px;
      margin-bottom: 12px;
      color: var(--v-textPrimary-lighten5);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__menu {
    margin-top: 1.75rem;

    &--event-menu {
      height: calc(100vh - 250px);
      overflow: auto;
      /* For Webkit browsers (Chrome, Safari) */
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--v-backgroundPrimary-lighten4);
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      /* For Firefox */
      scrollbar-width: auto;
      scrollbar-color: var(--v-backgroundPrimary-lighten4);

      .aside__item {
        margin-bottom: 1.75rem;
      }
    }

    &--profile {
      margin-top: 41px;
    }

    &--center {
      li {
        display: flex;
        align-items: center;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        color: var(--v-textPrimary-lighten5);
        cursor: pointer;
        border-radius: 4px;
        margin-bottom: 2px;

        svg {
          vertical-align: middle;
          margin-right: 2px;
          margin-left: 12px;
          width: 20px;
          height: 20px;
        }

        p {
          display: block;
          padding: 12px 10px;
          color: var(--v-textPrimary-lighten5);
        }

        &.active {
          background: var(--v-backgroundPrimary-lighten2);
          margin-right: 24px;
        }

        &.disabled {
          user-select: none;
          pointer-events: none;

          .aside__subItem {
            cursor: not-allowed;
            color: var(--v-textPrimary-lighten2);
          }
        }

        &.dropdown {
          display: block;
          position: relative;
          height: auto;

          user-select: none;
          -webkit-user-select: none; /* Chrome/Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */

          p.dropdown__expand {
            display: flex;
            justify-content: space-between;
            svg {
              margin-right: 10px;
              cursor: pointer;
              transition: all 0.2s ease-in-out;
              &.expanded {
                transform: rotate(180deg);
              }
            }
          }
        }

        &:not(.active):hover > p {
          font-weight: 700;
        }
      }
    }
  }

  &__logo {
    cursor: pointer;
    padding-left: 2px;

    img {
      height: 32px;
    }
  }

  &__item {
    margin-bottom: 32px;

    p {
      font-size: 14px;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item--hidden {
    display: none;
  }

  &__title {
    font-size: 13px;
    font-weight: 700;
    color: var(--v-textPrimary-lighten5);
    text-transform: uppercase;

    &--submenu {
      text-transform: initial;
      font-size: 14px;
    }

    &--profile-submenu {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 14px;
    }
  }

  &__subItem {
    margin-left: 14px;

    &--spaced {
      margin-top: 4px;
      display: none;

      &.visible {
        display: block;
      }
    }

    &--inside {
      margin-left: 36px;
      user-select: none;
    }

    &--hidden {
      display: none !important;
    }
  }

  &__main {
    height: 42px;
    p {
      display: inline-block !important;
    }
  }

  &__dropdown-icon {
    position: absolute;
    top: 11px;
    right: 28px;
  }
}

svg.visible {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
</style>
