/* eslint-disable no-unused-vars */
import { AxiosError } from 'axios';
import momentZone from 'moment-timezone';
import { FilterService } from 'primevue/api';
import Whitelabel from '@bam/whitelabel-sdk/dist/whitelabel';
import { BrandingType } from '@bam/whitelabel-sdk/dist/branding';

import { CompletePermissions, ApiError } from '@bam/sdk';
import { TicketConfig } from '../types/api';
import { Services } from '~/types/services';

export const USER_ROLES = {
  USER_MANAGEMENT: {
    name: 'User Management',
    permissions: [
      'account.user.create',
      'account.user.update',
      'account.user.delete',
      'account.user.view',
      'account.user.list',
      'account.service.view',
      'account.service.delete',
      'account.service.update',
      'account.service.list',
      'account.service.create',
      'account.organization.view',
      'account.organization.delete',
      'account.organization.update',
      'account.organization.list',
      'account.organization.create',
      'account.change_permissions',
      'account.user.change_permissions',
      'account.service.change_permissions',
      'account.organization.change_permissions'
    ]
  },
  EVENT_MANAGEMENT: {
    name: 'Event Management',
    permissions: [
      'bc.event.create',
      'bc.event.cancel',
      'bc.event.update_dates',
      'bc.ticket_config.create',
      'bc.ticket_config.cancel',
      'bc.ticket_config.update_price',
      'bc.ticket_config.update_quantity',
      'bc.ticket_config.update_dates',
      'bc.ticket_config.update_fee',
      'bc.ticket.cancel',
      'bc.ticket.update_owner',
      'bc.discount.create',
      'bc.discount.update',
      'bc.discount.update_dates',
      'bc.discount.cancel',
      'bc.change_permissions',
      'bc.discount.change_permissions',
      'bc.ticket.change_permissions',
      'bc.ticket_config.change_permissions',
      'bc.event.change_permissions',
      'event.event.view',
      'event.event.delete',
      'event.event.update',
      'event.event.list',
      'event.event.create',
      'event.change_permissions',
      'event.event.change_permissions',
      'payment.order.confirm',
      'payment.change_permissions',
      'account.organization.view',
      'bc.secondary_market_ruleset.set',
      'nft.nft.create',
      'nft.change_permissions',
      'nft.nft.change_permissions'
    ]
  },
  VALIDATOR: { name: 'Validator', permissions: ['bc.ticket.invalidate'] },
  PLATFORM_ADMIN: {
    name: 'Platform Admin',
    permissions: ['account.admin.impersonate']
  }
};
/**
 * Represents max video upload size expressed in megabytes.
 */
export const MAX_VIDEO_SIZE = 100;

export const DISCOUNT_TYPES = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percent'
};
export const TICKET_STATES = {
  DRAFT: 'draft',
  AVAILABLE: 'available',
  RESERVED: 'reserved',
  CONFIRMED: 'confirmed',
  ISSUED: 'issued',
  ACTIVATED: 'activated',
  UNAVAILABLE: 'unavailable',
  INVALIDATED: 'invalidated'
};

export const ORDER_STATES = {
  DRAFT: 'draft',
  PAID: 'paid',
  CANCELED: 'payment_failed',
  REFUNDED: 'refunded',
  PROCESSING: 'payment_processing'
};

export const PAYMENT_TYPES = {
  PAID: 'paid',
  FREE: 'free',
  DONATION: 'donation'
};

export const EVENT_STATES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  COMMITTED: 'committed',
  CANCELED: 'canceled',
  UPDATED: 'updated'
};

export const EVENT_TYPES = {
  SINGLE: 'single',
  RECURRING: 'recurring',
  OCCURRENCE: 'occurrence',
  EXTERNAL: 'external'
};

export const TICKET_FORMATS = {
  PDF: 'PDF',
  SECURE: 'SECURE',
  PKPASS: 'PKPASS'
};

export const TICKET_CONFIG_STATES = {
  DRAFT: 'draft',
  UPDATED: 'updated',
  COMMITTED: 'committed',
  PUBLISHED: 'published'
};

export const USER_TYPES = {
  ANON: 'anon',
  GUEST: 'guest'
};

export const FREQUENCY_TYPES = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  DAILY: 'daily'
};

export const MONTHLY_FREQUENCY_TYPES = {
  EVERY_N: 'every-n',
  FIXED: 'fixed'
};

export const FREQUENCY_ITERATION_TYPES = {
  END_DATE: 'endDate',
  NUMBER_OF_EVENTS: 'numberOfEvents'
};

export const KYC_LEVELS = {
  NOT_REQUIRED: 1,
  TICKET_HOLDER_INFO: 2,
  WITHOUT_FACE_MATCHING: 3,
  WITH_FACE_MATCHING: 4
};

export const TRANSACTION_RULESET_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  UPDATED: 'updated'
};

export const SEATS_EVENT_MANAGER_MODES = {
  OBJECT_STATUSES: 'manageObjectStatuses',
  FOR_SALE_CONFIG: 'manageForSaleConfig',
  TABLE_BOOKING: 'manageTableBooking'
}

export enum NftMediaTypes {
  IMAGE = 1,
  VIDEO = 2
}

export enum FeeSelection {
  Default = 0,
  Custom = 1
}

export interface DropdownItem {
  id: string | number;
  displayName: string;
}

/**
 * Represents the options for translating text.
 */
export interface TranslateOptions {
  // Define the properties of the options here
  [key: string]: any;
}

export const scrollToElement = (query: string) => {
  const el = document.querySelector(query);
  if (el) {
    el.scrollIntoView({ block: 'center' });
  }
};

/**
 * For a given user returns an array of roles where every role matches certain permissions
 * @param {*} permissions array of permissions
 * @returns array of roles that match given permissions
 */

export const permissionsToRoles = (permissions: string[]) => {
  const roles = [];
  for (const role in USER_ROLES) {
    if (
      USER_ROLES[role].permissions.every((perm: string) =>
        permissions.includes(perm)
      )
    ) {
      roles.push(USER_ROLES[role].name);
    }
  }
  return roles;
};

/**
 * Calculates lowest price in all combinations of a certain ticket config and its discounts
 *
 * @param config
 */
export const calculateSmallerPrice = (
  config: TicketConfig & { discounts: any[] }
) => {
  let smaller = null;
  for (const discount of config.discounts) {
    if (smaller === null || smaller > discount.finalPrice + discount.fees) {
      smaller = discount.finalPrice + discount.fees;
    }
  }
  return smaller?.toFixed(2) + ' ' + config.currency;
};

/**
 * Calculates lowest price in all combinations of ticket configs and discounts
 *
 * @param ticketConfigs
 */
export const calculateSmallerPriceAllConfigs = (
  ticketConfigs: TicketConfig[] & { discounts: any[] }[]
) => {
  let smaller = null;
  let currency = null;
  for (const config of ticketConfigs) {
    for (const discount of config.discounts) {
      if (smaller === null || smaller > discount.finalPrice + discount.fees) {
        smaller = discount.finalPrice + discount.fees;
        currency = config.currency;
      }
    }
  }
  return smaller?.toFixed(2) + ' ' + currency;
};

export const getFormattedDate = (date, timezone) => {
  return momentZone.parseZone(date).utcOffset(timezone, true).toISOString(true);
};

export const getFormattedTime = (date, time, timezone: string | number) => {
  let dateMoment = momentZone.parseZone(date);
  const timeMoment = momentZone.parseZone(time);
  dateMoment = dateMoment.set({
    hour: timeMoment.get('hour'),
    minute: timeMoment.get('minute'),
    second: 0,
    millisecond: 0
  });
  return momentZone
    .parseZone(dateMoment)
    .utcOffset(timezone, true)
    .toISOString(true);
};

export function getTimezoneFromName(date: string | Date, timezoneName: string) {
  return getTimezoneObject(date, timezoneName);
}

export const getTimezoneObject = (date: string | Date, zone: string) => {
  const momentObj = momentZone.tz(date, zone);
  const utc = momentObj.format('Z');
  const abbr = momentObj.zoneAbbr();
  let name = `${zone} (${utc})`;
  // added IANA to ISO conversion
  if (name.includes('Etc/GMT-')) {
    name = name.replace('GMT-', 'GMT+');
  } else if (name.includes('Etc/GMT+')) {
    name = name.replace('GMT+', 'GMT-');
  }
  return {
    name,
    nameValue: zone,
    timeValue: utc,
    group: zone.split('/', 1)[0],
    abbr
  };
};

export const getSplittedDate = (date) => {
  const dateMoment =
    date?.length > 0 ? momentZone.parseZone(date) : momentZone();
  return {
    date: dateMoment.format('YYYY-MM-DD'),
    time: dateMoment.format('HH:mm')
  };
};

export const getTimezones = () => {
  return momentZone.tz.names().map((zone) => {
    return getTimezoneObject(new Date(), zone);
  });
};
export const getBrowserTimezone = () => {
  const zone = momentZone.tz.guess(true);
  return getTimezoneObject(new Date(), zone);
};

export const getUniversalTimezone = () => {
  return getTimezoneObject(new Date(), 'Universal');
};

export const canvasToBlob = function (canvas) {
  return new Promise(function (resolve) {
    canvas.toBlob(resolve, 'image/webp');
  });
};
/**
 * Parses JWT token and extracts info
 *
 * @param token
 */
export const parseJwt = function (token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  const jwtData = JSON.parse(jsonPayload);
  if (jwtData.enrollment_id) {
    jwtData.enrollmentId = jwtData.enrollment_id;
  }
  return jwtData;
};

/**
 * Calculates dates to include in schedule depending to option. Add every selected days of week from start to end date or
 * until limit reached.
 *
 * @param schedule
 * @param timezone
 */
export const getWeeklyOccurrences = function (schedule, timezone) {
  const {
    startAt,
    endAt,
    gateTime,
    frequencyIterationType,
    weeklyFrequency,
    numberOfEvents
  } = schedule;
  const checkEndDate =
    frequencyIterationType === FREQUENCY_ITERATION_TYPES.END_DATE;
  const dates = [];
  const momentStart = momentZone.parseZone(startAt);
  const momentEnd = momentZone.parseZone(endAt);
  const currentDate = momentStart.clone();
  while (
    checkEndDate
      ? currentDate.isSameOrBefore(momentEnd)
      : dates.length < numberOfEvents
  ) {
    for (const i of weeklyFrequency.sort()) {
      const tempStartDate = currentDate.clone().day(i);
      if (tempStartDate.isSameOrAfter(momentStart)) {
        if (
          checkEndDate
            ? tempStartDate.isSameOrBefore(momentEnd)
            : dates.length < numberOfEvents
        ) {
          dates.push({
            startAt: getFormattedTime(tempStartDate, startAt, timezone),
            endAt: getFormattedTime(tempStartDate, endAt, timezone),
            gateTime: getFormattedTime(
              currentDate,
              gateTime || startAt,
              timezone
            )
          });
        } else {
          break;
        }
      }
    }
    currentDate.add(1, 'week');
  }
  return dates;
};
/**
 * Calculates dates to include in schedule depending to option. Add every day between dates or until limit reached.
 *
 * @param schedule
 * @param timezone
 */
export const getDailyOccurrences = function (schedule, timezone) {
  const { startAt, endAt, gateTime, frequencyIterationType, numberOfEvents } =
    schedule;
  const checkEndDate =
    frequencyIterationType === FREQUENCY_ITERATION_TYPES.END_DATE;
  const dates = [];
  const momentStart = momentZone.parseZone(startAt);
  const momentEnd = momentZone.parseZone(endAt);
  const currentDate = momentStart.clone();
  while (
    checkEndDate
      ? currentDate.isSameOrBefore(momentEnd)
      : dates.length < numberOfEvents
  ) {
    dates.push({
      startAt: getFormattedTime(currentDate, startAt, timezone),
      endAt: getFormattedTime(currentDate, endAt, timezone),
      gateTime: getFormattedTime(currentDate, gateTime || startAt, timezone)
    });
    currentDate.add(1, 'day');
  }
  return dates;
};

/**
 * Calculates dates to include in schedule depending to option. If Frequency is EVERY_N ,add every Nth monday,
 * friday, etc of each month.
 * Else add every fixed date of each month
 *
 * @param schedule
 * @param timezone
 */
export const getMonthlyOccurrences = function (schedule, timezone) {
  const {
    startAt,
    endAt,
    gateTime,
    frequencyIterationType,
    numberOfEvents,
    monthlyFrequency
  } = schedule;
  const checkEndDate =
    frequencyIterationType === FREQUENCY_ITERATION_TYPES.END_DATE;
  const dates = [];
  const momentStart = momentZone.parseZone(startAt);
  const momentEnd = momentZone.parseZone(endAt);
  let currentDate = momentStart.clone();
  while (
    checkEndDate
      ? currentDate.isSameOrBefore(momentEnd)
      : dates.length < numberOfEvents
  ) {
    dates.push({
      startAt: getFormattedTime(currentDate, startAt, timezone),
      endAt: getFormattedTime(currentDate, endAt, timezone),
      gateTime: getFormattedTime(currentDate, gateTime || startAt, timezone)
    });
    if (monthlyFrequency === MONTHLY_FREQUENCY_TYPES.EVERY_N) {
      const startOfMonth = currentDate.clone().startOf('month');
      const diff = currentDate.diff(startOfMonth, 'week');
      const day = currentDate.days();
      let i = 1;
      let nextMonth = currentDate.clone().startOf('month').add(i, 'month');
      if (nextMonth.days() > day) {
        nextMonth.add(1, 'week');
      }
      nextMonth.days(day);
      let testMoment = nextMonth.clone().add(diff, 'week');
      while (nextMonth.month() !== testMoment.month()) {
        i++;
        nextMonth = currentDate.clone().add(i, 'month').startOf('month');
        if (nextMonth.days() > day) {
          nextMonth.add(1, 'week');
        }
        nextMonth.days(day);
        testMoment = nextMonth.clone().add(diff, 'week');
      }
      currentDate = testMoment;
    } else {
      let i = 1;
      let tempMoment = currentDate.clone().add(i, 'month');
      while (tempMoment.daysInMonth() < currentDate.date()) {
        i++;
        tempMoment = currentDate.clone().add(i, 'month');
      }
      currentDate = tempMoment;
    }
  }
  return dates;
};

/**
 * Calculates price range of given list of ticketConfigs
 *
 * @param ticketConfigs
 */
export const calculatePriceRange = (ticketConfigs) => {
  let smaller = null;
  let greater = null;
  let currency = null;
  for (const config of ticketConfigs) {
    for (const discount of config.discounts) {
      if (smaller === null || smaller > discount.finalPrice + discount.fees) {
        smaller = discount.finalPrice + discount.fees;
        currency = config.currency;
      }
      if (greater === null || greater < discount.finalPrice + discount.fees) {
        greater = discount.finalPrice + discount.fees;
        currency = config.currency;
      }
    }
  }
  return {
    smallerPrice: smaller?.toFixed(2) + ' ' + currency,
    greaterPrice: greater?.toFixed(2) + ' ' + currency
  };
};

/**
 * Returns a "deep" copy of an object.
 *
 * To avoid shallow copy (especially while using vuex stores), copy an object
 * with the usage of JSON.parse() and JSON.stringify(). The method gets an
 * object and returns its clone.
 *
 * @function clone
 * @param {Object} object The object you want to make a copy of it.
 * @returns {Object} Returns a clone of the object.
 */
export const clone = <T>(object: T): T => JSON.parse(JSON.stringify(object));

export const setTimeoutPromise = (cb: () => Promise<void>, timeout: number) => {
  return new Promise<void>((resolve, reject) =>
    setTimeout(async () => {
      try {
        await cb();
        resolve();
      } catch (err) {
        reject(err);
      }
    }, timeout)
  );
};

/**
 * Translates the given key using the provided options.
 * If running in a client-side environment, it uses the injected $i18n instance from Nuxt for translation.
 * If translation is not available, it returns the key itself.
 *
 * @param key - The key to be translated.
 * @param options - Additional options for translation.
 * @returns The translated string.
 */
export function translate(key: string, options: any = {}): string {
  // Check if we are running in a client-side environment
  if (process.client && window.$nuxt) {
    // Use the injected $i18n instance from Nuxt
    return window.$nuxt.$i18n.t(key, options).toString();
  }

  // Fallback: return the key if translation is not available
  return key;
}

/**
 * Returns error message from given response or generic error message
 * @param {Object} error Error object from the response
 * @param {String} genericError String with generic error message
 * @returns {String} error message from error response (if it exists) or generic error message
 */
export const errorMessage = (
  error: AxiosError | ApiError,
  genericError: string,
  options?: TranslateOptions
): string => {
  let message: string;
  if (isAxiosError(error)) {
    // Handle axios error
    const errorData = error.response?.data as any;
    message = errorData.code
      ? translate(errorData.code, options)
      : errorData.message;
  } else {
    // Handle SDK error
    message = error.errorCode
      ? translate(error.errorCode, options)
      : error.message;
  }

  return message || genericError;
};

function isAxiosError(error: any): error is AxiosError {
  return error.response?.data?.message;
}

/**
 * Returns a string with capitalized first letter
 * @param {String} string Input string
 * @returns {String} The input string but with capitalized first letter
 */
export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Returns a string transformed from camelCase to Capitalized Words
 * @param {String} text Input string
 * @returns {String} The input string but transformed
 */
export const camel2title = (text: string): string => {
  return text
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

/**
 * Returns a string transformed from snake_case to camelCase
 * @param {String} text Input string
 * @returns {String} The input string but transformed
 */
export const snakeToCamel = (text: string): string =>
  text
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );

/**
 * Returns a string transformed from any case to snake_case
 * @param {String} text Input string
 * @returns {String} The input string but transformed
 */
export const toSnakeCase = (text: string): string | undefined => {
  if (text != null) {
    return text
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      ?.map((x) => x.toLowerCase())
      .join('_');
  }
};

/**
 * Returns a boolean value signaling whether the object is empty or not.
 * @function isEmptyObject
 * @param {Object} object The object you want to check for being an empty one or not.
 * @returns {Boolean} Returns a boolean value signaling whether the object is empty or not.
 */
export const isEmptyObject = (object: Object): boolean => {
  return Object.keys(object).length === 0;
};

/**
 * Returns array of organizers for which user has permissions to access event and/or user management.
 * @function getOrganizers
 * @param {Object} permissions Object whose properties are organizers to which user belongs.
 * @param {Function} canAccessDashboard Helper function (getter from user store) which filters
 * organizers for which user has permissions to access event and/or user management.
 * @param {String} mainOrganizer Main organizer defined in .env file.
 * @returns {Array} Returns array of organizers for which user has permissions to access event and/or user management.
 */

export const getOrganizers = async (
  permissions: CompletePermissions,
  services: Pick<Services, 'event'>,
  canAccessDashboard: (mainOrganizer: string, organizer: string) => boolean,
  mainOrganizer: string
): Promise<any[]> => {
  const allOrganizers = Object.keys(permissions).filter(
    (org) => permissions[org].org_level || permissions[org].orgLevel
  );
  let organizers = await Promise.all(
    allOrganizers.map(async (org) => await services.event.getOrganizer(org))
  );
  organizers = organizers.filter((o) =>
    canAccessDashboard(mainOrganizer, o.name)
  );
  return organizers;
};

/**
 * Returns a boolean value signaling if user is a regular one (doesn't belong to any organizer).
 * @function isRegularUser
 * @param {Object} permissions Object whose properties are organizer to which user 'belongs'.
 * @param {String} mainOrganizer Main organizer defined in .env file.
 * @returns {Boolean} Returns a boolean value signaling if user is a regular one (doesn't belong to any organizer).
 */

export const isRegularUser = (
  permissions: CompletePermissions,
  mainOrganizer: string
): boolean => {
  return (
    !permissions[mainOrganizer].org_level &&
    Object.keys(permissions).length === 1
  );
};

/**
 * Inject dynamic CSS classes based on whitelabel-SDK and the selected branding-type
 * @param brandingType
 */
export const getWhitelabelCSS = (brandingType: BrandingType) => {
  // initialize whitelabel-sdk and create base style/css tag
  Whitelabel.init(brandingType);

  // construct CSS content by iterating over typography definition
  let cssContent = '';
  for (const [className, classProperties] of Object.entries(
    Whitelabel.getInstance().TYPOGRAPHY
  )) {
    let classContent = '';
    for (const [propertyName, propertyValue] of Object.entries(
      classProperties
    )) {
      // map the react-native based property naming to standard CSS-properties
      let convertedPropertyName = propertyName.replace(
        /[A-Z]/g,
        (letter) => `-${letter.toLowerCase()}`
      );

      // lookup & mapping of react-native property values to css
      let convertedPropertyValue: string | number = '';
      switch (convertedPropertyName) {
        case 'font-family':
          convertedPropertyName = 'font-weight';
          switch (propertyValue) {
            case 'Inter-Medium':
              convertedPropertyValue = '500';
              break;
            case 'Inter-Semibold':
              convertedPropertyValue = '600';
              break;
            case 'Inter-Bold':
              convertedPropertyValue = '700';
              break;
            default:
              convertedPropertyValue = '400';
              break;
          }
          break;
        case 'font-size':
        case 'line-height':
        case 'letter-spacing':
          convertedPropertyValue = propertyValue + 'px';
          break;
        default:
          convertedPropertyValue = propertyValue;
          break;
      }
      classContent +=
        convertedPropertyName + ': ' + convertedPropertyValue + ';\n';
    }
    cssContent += '.' + className + '{' + classContent + '}\n';
  }

  return cssContent;
};

/**
 * Custom filter implementation for matching two arrays against each other
 */
FilterService.register(
  'MultiArrayIn',
  (data: string[], filters: string[] | null) => {
    // empty filters just allow everything
    if (!filters || filters.length <= 0) {
      return true;
    }

    // non-empty filter but empty data => no match
    if (!data || data.length <= 0) {
      return false;
    }

    // iterate over each filter and check if the entry exists in the data
    let matches = true;
    filters.forEach((filter) => {
      const foundIndex = data.findIndex((ts) => ts === filter);
      matches = matches && foundIndex >= 0;
    });

    return matches;
  }
);

/**
 * Converts an array of items to a map with the item's id as the key
 */
export function arrayToMap<T extends { id: any }>(items: T[]): Map<T['id'], T> {
  const map = new Map();
  if (!items) return map;

  for (const item of items) {
    map.set(item.id, item);
  }
  return map;
}

/**
 * Lightens the given color (hex format) for the given number
 * which represents the level of lighteness defined in config colors
 */
export function lightenColor(color, ligtnessLevel) {
  const num = parseInt(color.replace('#', ''), 16);
    const rAmount = 51;
    const gAmount = 43;
    const bAmount = 36;
    const R = (num >> 16) + rAmount * ligtnessLevel;
    const G = ((num >> 8) & 0x00ff) + gAmount * ligtnessLevel;
    const B = (num & 0x0000ff) + bAmount * ligtnessLevel;
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
}

/**
 * Changes app colors according to the provided base color
 */
export function changeColorPalette(color) {
  return {
      '--v-backgroundPrimary-base': `#${color}`,
      '--v-textPrimary-base': `#${color}`,
      '--v-borderPrimary-base': `#${color}`,
      '--v-buttonPrimary-base': `#${color}`,
      '--v-backgroundPrimary-lighten1': `${lightenColor('#' + color, 1)}`,
      '--v-textPrimary-lighten1': `${lightenColor('#' + color, 1)}`,
      '--v-borderPrimary-lighten1': `${lightenColor('#' + color, 1)}`,
      '--v-buttonPrimary-lighten1': `${lightenColor('#' + color, 1)}`,
      '--v-backgroundPrimary-lighten2': `${lightenColor('#' + color, 2)}`,
      '--v-textPrimary-lighten2': `${lightenColor('#' + color, 2)}`,
      '--v-borderPrimary-lighten2': `${lightenColor('#' + color, 2)}`,
      '--v-buttonPrimary-lighten2': `${lightenColor('#' + color, 2)}`,
      '--v-backgroundPrimary-lighten3': `${lightenColor('#' + color, 3)}`,
      '--v-textPrimary-lighten3': `${lightenColor('#' + color, 3)}`,
      '--v-borderPrimary-lighten3': `${lightenColor('#' + color, 3)}`,
      '--v-buttonPrimary-lighten3': `${lightenColor('#' + color, 3)}`,
      '--v-backgroundPrimary-lighten4': `${lightenColor('#' + color, 4)}`,
      '--v-textPrimary-lighten4': `${lightenColor('#' + color, 4)}`,
      '--v-borderPrimary-lighten4': `${lightenColor('#' + color, 4)}`,
      '--v-buttonPrimary-lighten4': `${lightenColor('#' + color, 4)}`,
      '--v-backgroundPrimary-lighten5': '#FFF',
      '--v-textPrimary-lighten5': '#FFF',
      '--v-borderPrimary-lighten5': '#FFF'
    };
}


